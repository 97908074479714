import axios from 'lib/axios'
import { setDownForMaintenance } from 'lib/actions/downForMaintenanceActions'
import { isHostedEnv } from 'utils/env';

function success({ baseType, dispatch, response, extractPayload, nextAction }) {
  let payload = extractPayload ? extractPayload(response) : response.data
  dispatch({ type: `${baseType}_FULFILLED`, payload })
  if (nextAction) dispatch(nextAction(response))
  return response
}

// expectedErrorStatusCodes allows to mark specific http status codes as an expected outcome
// this should skip alerting this specific status code as an error to sentry.
function failure({ baseType, dispatch, error, expectedErrorStatusCodes = [], errorContext }) {
  dispatch({ type: `${baseType}_REJECTED`, payload: error })

  if (error.response.status === 503) {
    dispatch(setDownForMaintenance(true))
    return
  } 

  if (errorContext) {
    // When errorContext is passed in, replace error's stackTrace with more detailed alternative.
    error.stack = errorContext.stack.replace(errorContext.message, error.message);
    if (!isHostedEnv()) { // When running locally, log the error to the console.
      console.error(error) // eslint-disable-line no-console
    }
  }

  if (expectedErrorStatusCodes.length > 0) {
    if (expectedErrorStatusCodes.indexOf(error.response.status) !== -1) {
      return
    }
  }

  // If the error hasn't already been handled, throw error.
  throw error
}

function get({
  baseType,
  url,
  nextAction,
  extractPayload = null,
  expectedErrorStatusCodes = [],
}) {
  const errorContext = new Error("Thrown at:");
  return function (dispatch) {
    dispatch({ type: baseType })
    return axios
      .get(url)
      .then(response => {
        return success({ baseType, dispatch, response, nextAction, extractPayload })
      })
      .catch(error => {
        return failure({ baseType, dispatch, error, expectedErrorStatusCodes, errorContext })
      })
  }
}

function patch({ baseType, url, body, nextAction, extractPayload = null }) {
  const errorContext = new Error("Thrown at:");
  return function (dispatch) {
    dispatch({ type: baseType })
    return axios
      .patch(url, body)
      .then(response => {
        return success({ baseType, dispatch, response, nextAction, extractPayload })
      })
      .catch(error => {
        return failure({ baseType, dispatch, error, errorContext })
      })
  }
}

function put({ baseType, url, body, nextAction, extractPayload = null }) {
  const errorContext = new Error("Thrown at:");
  return function (dispatch) {
    dispatch({ type: baseType })
    return axios
      .put(url, body)
      .then(response => {
        return success({ baseType, dispatch, response, nextAction, extractPayload })
      })
      .catch(error => {
        return failure({ baseType, dispatch, error, errorContext })
      })
  }
}

function post({ baseType, url, body, nextAction, extractPayload = null }) {
  const errorContext = new Error("Thrown at:");
  return function (dispatch) {
    dispatch({ type: baseType })
    return axios
      .post(url, body)
      .then(response => {
        return success({ baseType, dispatch, response, nextAction, extractPayload })
      })
      .catch(error => {
        return failure({ baseType, dispatch, error, errorContext })
      })
  }
}

function destroy({ baseType, url, body, nextAction }) {
  const errorContext = new Error("Thrown at:");
  return function (dispatch) {
    dispatch({ type: baseType })
    return axios
      .delete(url, body)
      .then(response => {
        return success({ baseType, dispatch, response, nextAction })
      })
      .catch(error => {
        return failure({ baseType, dispatch, error, errorContext })
      })
  }
}

export { get, patch, put, post, destroy }
